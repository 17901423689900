import { Box, Image as ChakraImage } from '@chakra-ui/react';
import { TokenURIContent, TokenURIError, TokenURIType } from 'utils/token-uri.utils';

const NFTPreviewUnavailable = ({ error }: { error: TokenURIError | null }) => {
  return (
    <Box textAlign="center">
      <Box>Unable to render preview</Box>
      {error && <Box>({error})</Box>}
    </Box>
  );
};

export const NFTPreview = ({ tokenURIContent }: { tokenURIContent: TokenURIContent }) => {
  if (tokenURIContent.error || !tokenURIContent.media) {
    return <NFTPreviewUnavailable error={tokenURIContent.error} />;
  }
  const { media } = tokenURIContent;

  if (media.response.type === TokenURIType.IMAGE) {
    return (
      <ChakraImage
        src={media.uriNormalized}
        maxH="1000"
        onError={(e) => console.log('image load error', e)}
      />
    );
  }

  if (media.response.type === TokenURIType.VIDEO) {
    return <video style={{ maxWidth: '320px' }} controls src={media.uriNormalized}></video>;
  }

  if (media.response.type === TokenURIType.AUDIO) {
    return <audio src={media.uriNormalized}></audio>;
  }

  return <NFTPreviewUnavailable error={null} />;
};
