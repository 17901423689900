// Can't expose etherscan API keys, so instead use highly rate-limited
// endpoint without API key with localStorage cache
import { EtherscanABIResult } from './etherscan';

// invalidate cache by incrementing version
const cacheVersion = 'v1';

export const getCachedABI = ({
  blockchain,
  contractAddress,
}: {
  blockchain: string;
  contractAddress: string;
}): EtherscanABIResult | null => {
  const key = getStorageKey({ blockchain, contractAddress });
  const result = localStorage.getItem(key);
  if (result) {
    return JSON.parse(result);
  }
  return null;
};

export const setCachedABI = ({
  blockchain,
  contractAddress,
  abi,
}: {
  blockchain: string;
  contractAddress: string;
  abi: EtherscanABIResult;
}): void => {
  const key = getStorageKey({ blockchain, contractAddress });
  return localStorage.setItem(key, JSON.stringify(abi));
};

const getStorageKey = ({
  blockchain,
  contractAddress,
}: {
  blockchain: string;
  contractAddress: string;
}): string => {
  // Addresses are not case-sensitive (for eth at least..)
  const normalizedContractAddress = contractAddress.toLocaleLowerCase();
  return `artscan:${cacheVersion}:${blockchain}:${normalizedContractAddress}`;
};
