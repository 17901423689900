import { useCallback, useState } from 'react';
import { PageContainer } from 'components/page-container';
import { extractNFTInfoFromURL, NFTURLError, NFTURLInfo } from 'utils/nft-marketplace.utils';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Wrap,
} from '@chakra-ui/react';
import { CheckIcon, SearchIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { useThemeColors } from 'hooks/use-theme-colors';

const SAMPLE_ADDRESSES = {
  EOA: '0xAb5801a7D398351b8bE11C439e05C5B3259aeC9B',
  ERC20: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  ERC721_Artblocks_V1: '0x059EDD72Cd353dF5106D2B9cC5ab83a52287aC3a',
  ERC721_Artblocks_V2: '0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270',
  ERC721_Autoglyphs: '0xd4e4078ca3495de5b1d4db434bebc5a986197782',
  ERC721_Azuki: '0xed5af388653567af2f388e6224dc7c4b3241c544',
  ERC721_BAYC: '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d',
  ERC721_CloneX: '0x49cf6f5d44e70224e2e23fdcdd2c053f30ada28b',
  ERC721_Cryptoadz: '0x1cb1a5e65610aeff2551a50f76a87a7d3fb649c6',
  ERC721_CryptoArte: '0xbace7e22f06554339911a03b8e0ae28203da9598',
  ERC721_CyberKongz: '0x57a204aa1042f6e66dd7730813f4024114d74f37',
  ERC721_Doodles: '0x8a90cab2b38dba80c64b7734e58ee1db38b8992e',
  ERC721_Foundation: {
    Proxy: '0x3b3ee1931dc30c1957379fac9aba94d1c48a5405',
    Underlying: '0xe7c29cba93ef8017c7824dd0f25923c38d08065c',
  },
  // Most expensive NFT = tokenID 40913
  ERC721_MakersPlace: '0x2a46f2ffd99e19a89476e2f62270e0a35bbf0756',
  ERC721_Meebits: '0x7bd29408f11d2bfc23c34f18275bbf23bb716bc7',
  ERC721_Moonbirds: '0x23581767a106ae21c074b2276d25e5c3e136a68b',
  // All data is entirely onchain in `data` URIs
  ERC721_Nouns: '0x9c8ff314c9bc7f6e59a9d9225fb22946427edc03',
  // All data is entirely onchain in `data` URIs
  ERC721_Posers: '0x02beed1404c69e62b76af6dbdae41bd98bca2eab',
  ERC721_Rarible: '0x60f80121c31a0d46b5279700f9df786054aa5ee5',
  ERC721_SuperRare: '0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0',
  ERC721_TheMerge: '0xE42caD6fC883877A76A26A16ed92444ab177E306',
  ERC1155_Adidas: '0x28472a58a490c5e09a238847f66a68a47cc76f0f',
  ERC1155_DeadRingers: '0xc4c377565a4b9eb6e657c2422bd33b6e4859b041',
  ERC1155_EulerBeats: '0x8754f54074400ce745a7ceddc928fb1b7e985ed6',
  ERC1155_LuisPonce: {
    Proxy: '0x8782695452adfc00ecdac202fa53e5a13dba8073',
    Underlying: '0x142fd5b9d67721efda3a5e2e9be47a96c9b724a4',
  },
  ERC1155_OpenSea_Unverified: '0x495f947276749ce646f68ac8c248420045cb7b5e',
  ERC1155_Parallel: '0x76be3b62873462d2142405439777e971754e8e77',
  ERC1155_Rarible: {
    Proxy: '0xb66a603f4cfe17e3d27b87a8bfcad319856518b8',
    Underlying: '0x8cb617a1e1cac6724d6b934ae5c5fd1db2037666',
    NonProxy: '0xd07dc4262bcdbf85190c01c996b4c06a461d2430',
  },
  ERC1155_Sandbox: {
    Proxy: '0xa342f5d851e866e18ff98f351f2c6637f4478db5',
    Underlying: '0x7fbf5c9af42a6d146dcc18762f515692cd5f853b',
  },
  SpecialCases: {
    CryptoPunks: {
      Original: '0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb',
      ERC721_Wrapped: '0xb7f7f6c52f2e2fdb1963eab30438024864c313f6',
      OnChainImageAndMetadata: '0x16f5a35647d6f03d5d3da7b35409d65ba03af3b2',
    },
    ENS: {
      Original: '0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85',
    },
    EtherRock: {
      Original: '0x41f28833be34e6ede3c58d1f597bef429861c4e2',
      ERC721_Wrapped: '0xa3f5998047579334607c47a6a2889bf87a17fc02',
    },
    Mooncats: {
      Original: '0x60cd862c9c687a9de49aecdc3a99b74a4fc54ab6',
      ERC721_Official: '0xc3f733ca98e0dad0386979eb96fb1722a1a05e69',
      ERC721_Wrapped: '0x7c40c393dc0f283f318791d746d894ddd3693572',
    },
  },
};

const getReadableError = (errorType: NFTURLError | null): string => {
  if (errorType === NFTURLError.UNSUPPORTED_BLOCKCHAIN) {
    return `Currently only Ethereum-based NFTs are supported.`;
  } else if (
    errorType === NFTURLError.UNSUPPORTED_MARKETPLACE ||
    errorType === NFTURLError.INVALID_URL
  ) {
    return `Unsupported NFT marketplace. Try a popular Ethereum-based NFT website like OpenSea.`;
  }
  return `Enter a URL for a specific NFT token (not a collection).`;
};

// Given an NFT URL, extract the relevant details (blockchain + contractAddress
// + tokenId) and pass it to InspectorScreen.
export const DashboardScreen = () => {
  const navigate = useNavigate();
  const [nftInfo, setNftInfo] = useState<NFTURLInfo | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [didPressScanAtLeastOnce, setDidPressScanAtLeastOnce] = useState<boolean>(false);

  const {
    containerBgColor,
    containerBorderColor,
    buttonBgColor,
    buttonTextColor,
    textColor,
    textInputBgColor,
    textInputBorderColor,
    textInputPlaceholderColor,
  } = useThemeColors();

  const onSearchUrl = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      setDidPressScanAtLeastOnce(true);
      if (!nftInfo) {
        return;
      }
      navigateToNFT({ address: nftInfo.contractAddress, tokenId: nftInfo.tokenId });
    },
    [nftInfo, navigate, setDidPressScanAtLeastOnce],
  );

  const navigateToNFT = useCallback(
    ({ address, tokenId }: { address: string; tokenId: string }) => {
      navigate(`/eth/${address}/${tokenId}`);
    },
    [navigate],
  );

  const onChangeUrl = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const rawUrl = e.target.value;

    // If URL input is cleared, just reset all state:
    if (!rawUrl) {
      setErrorMessage(null);
      setNftInfo(null);
      return;
    }

    const { info, error } = extractNFTInfoFromURL(rawUrl);
    console.log({ info, error });
    if (error || !info) {
      setErrorMessage(getReadableError(error));
    } else {
      setErrorMessage(null);
      setNftInfo(info);
    }
  }, []);

  return (
    <PageContainer>
      <Box
        backgroundColor={containerBgColor}
        borderColor={containerBorderColor}
        borderWidth="1px"
        borderRadius="md"
      >
        <form onSubmit={onSearchUrl}>
          <FormControl
            isRequired
            isInvalid={!!errorMessage}
            onSubmit={onSearchUrl}
            marginBottom="10"
          >
            <Box flex="1">
              <Center marginTop="8">
                <Text fontSize="xl" color={textColor}>
                  Enter an NFT URL to scan:
                </Text>
              </Center>
            </Box>
            <HStack spacing={'0'} marginTop="4" maxW="800px" marginLeft="auto" marginRight="auto">
              <InputGroup size="lg">
                <Input
                  size="lg"
                  autoFocus
                  autoCorrect="false"
                  placeholder="https://opensea.io/assets/..."
                  onChange={onChangeUrl}
                  borderRightRadius={0}
                  backgroundColor={textInputBgColor}
                  borderColor={textInputBorderColor}
                  _placeholder={{ color: textInputPlaceholderColor }}
                />
                {nftInfo && <InputRightElement children={<CheckIcon color="green.500" />} />}
              </InputGroup>
              <Button
                colorScheme="purple"
                backgroundColor={buttonBgColor}
                color={buttonTextColor}
                size="lg"
                aria-label="Search"
                rightIcon={<SearchIcon />}
                type="submit"
                borderLeftRadius={0}
              >
                Scan
              </Button>
            </HStack>
            {errorMessage && didPressScanAtLeastOnce && (
              <Center>
                <FormErrorMessage marginTop="5">
                  <WarningTwoIcon marginRight="2" />
                  {errorMessage}
                </FormErrorMessage>
              </Center>
            )}
          </FormControl>
        </form>
      </Box>
      <Box flex="1">
        <Center marginTop="6">
          <Text fontSize="xl" color={textColor}>
            or pick an example:
          </Text>
        </Center>
      </Box>
      <Center>
        <Wrap marginTop="5" spacing="4" justify="center" maxW="640px">
          <Button
            onClick={() =>
              navigateToNFT({
                address: SAMPLE_ADDRESSES.ERC721_Cryptoadz,
                tokenId: '392',
              })
            }
          >
            Cryptoadz
          </Button>
          <Button
            onClick={() =>
              navigateToNFT({
                address: SAMPLE_ADDRESSES.ERC721_CloneX,
                tokenId: '18546',
              })
            }
          >
            CloneX
          </Button>
          <Button
            onClick={() =>
              navigateToNFT({
                address: SAMPLE_ADDRESSES.ERC721_Artblocks_V2,
                tokenId: '78000692',
              })
            }
          >
            Artblocks
          </Button>
          <Button
            onClick={() =>
              navigateToNFT({
                address: SAMPLE_ADDRESSES.ERC1155_Parallel,
                tokenId: '10306',
              })
            }
          >
            Parallel Alpha
          </Button>
          <Button
            onClick={() =>
              navigateToNFT({
                address: SAMPLE_ADDRESSES.ERC1155_DeadRingers,
                tokenId: '1',
              })
            }
          >
            Dead Ringers
          </Button>
          <Button
            onClick={() =>
              navigateToNFT({
                address: SAMPLE_ADDRESSES.ERC1155_Adidas,
                tokenId: '0',
              })
            }
          >
            Adidas Originals
          </Button>
          <Button
            onClick={() =>
              navigateToNFT({
                address: SAMPLE_ADDRESSES.ERC721_Nouns,
                tokenId: '207',
              })
            }
          >
            Nouns
          </Button>
          <Button
            onClick={() =>
              navigateToNFT({
                address: SAMPLE_ADDRESSES.ERC721_CyberKongz,
                tokenId: '30',
              })
            }
          >
            CyberKongz
          </Button>
        </Wrap>
      </Center>
    </PageContainer>
  );
};
