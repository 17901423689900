import { Center, Container, Heading, Text, useColorModeValue } from '@chakra-ui/react';
import { ColorModeToggle } from 'components/color-mode-toggle';
import { useNavigate } from 'react-router-dom';

export const PageContainer = ({ children }: React.PropsWithChildren) => {
  const navigate = useNavigate();
  const headerTextColor = useColorModeValue('#3f347e', '#fdfdff');
  const headerHoverTextColor = useColorModeValue('#261d5d', '#e6e2fb');
  const subheaderTextColor = useColorModeValue('#6467ac', '#a7a7e7');

  return (
    <Container maxW="900px" paddingTop="5" paddingBottom="10">
      <ColorModeToggle />
      <Heading
        fontSize="3xl"
        color={headerTextColor}
        textAlign="center"
        letterSpacing="-0.6px"
        _hover={{ color: headerHoverTextColor }}
      >
        <span style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
          ArtScan 🕵️
        </span>
      </Heading>
      <Center marginBottom="12">
        <Text
          marginTop="2"
          fontSize="xl"
          textAlign="center"
          maxW="md"
          alignSelf="center"
          color={subheaderTextColor}
        >
          See how NFTs work under the hood.
        </Text>
      </Center>
      {children}
    </Container>
  );
};
