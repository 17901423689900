import { ABIEntry } from 'types/abi-json.types';
import { getCachedABI, setCachedABI } from './abi-cache-storage';

interface EtherscanParsedResponse<T> {
  error?: string;
  result: T;
}

// *scan (Etherscan.io family) of explorers:
// https://info.etherscan.com/api-return-errors/
interface EtherscanApiResponse<T> {
  // 1 = OK, 0 = NOT OK
  status: '1' | '0';
  message: string | void;
  // string/array/object
  result: T;
}

const RATE_LIMIT_REACHED_REGEX = /Max rate limit reached/;
// {
//   "status": "0",
//   "message": "NOTOK",
//   "result": "Max rate limit reached, please use API Key for higher rate limit"
// }

export type EtherscanABIResult = Array<ABIEntry>;
export enum EtherscanABIError {
  CONTRACT_NOT_VERIFIED = 'CONTRACT_NOT_VERIFIED',
  RATE_LIMIT_REACHED = 'RATE_LIMIT_REACHED',
}

export interface FetchABIAPI {
  result?: EtherscanABIResult;
  error: EtherscanABIError | null;
}

type EtherscanProps = { name: string; baseURL: string; apiURL: string; apiKey?: string };
export class Etherscan {
  name: string;
  baseURL: string;
  apiURL: string;
  apiKey?: string;

  constructor({ name, baseURL, apiURL, apiKey }: EtherscanProps) {
    this.name = name;
    this.baseURL = baseURL;
    this.apiURL = apiURL;
    this.apiKey = apiKey;
  }

  getAddressURL(address: string): string {
    return `${this.baseURL}/address/${address}`;
  }

  getBlockURL(block: number): string {
    return `${this.baseURL}/block/${block}`;
  }

  getTokenURL(tokenAddress: string): string {
    return `${this.baseURL}/token/${tokenAddress}`;
  }

  getTransactionURL(transactionHash: string): string {
    return `${this.baseURL}/tx/${transactionHash}`;
  }

  // see docs on different ABI formats:
  // https://docs.ethers.io/v5/api/utils/abi/formats/#abi-formats
  // Etherscan returns "Solidity JSON ABI"
  //
  // NOTE: if unverified, will fail with:
  // {
  //   status: '0',
  //   message: 'NOTOK',
  //   result: 'Contract source code not verified'
  // }
  //
  // NOTE: rate limit for this endpoint is 1 req per 5 sec per IP:
  async fetchABI({
    contractAddress,
    cache = true,
  }: {
    contractAddress: string;
    cache?: boolean;
  }): Promise<FetchABIAPI> {
    if (cache) {
      const cachedABI = getCachedABI({ blockchain: 'ethereum', contractAddress });
      if (cachedABI) {
        return { error: null, result: cachedABI };
      }
    }

    const { error, result } = await this.apiFetch<string>({
      module: 'contract',
      action: 'getabi',
      address: contractAddress,
    });
    if (error && result === 'Contract source code not verified') {
      return { error: EtherscanABIError.CONTRACT_NOT_VERIFIED };
    } else if (error && result.match(RATE_LIMIT_REACHED_REGEX)) {
      return { error: EtherscanABIError.RATE_LIMIT_REACHED };
    } else if (error) {
      throw new Error(`Etherscan#fetchABI unhandled error: ${error}`);
    }

    const parsedABI = JSON.parse(result);
    if (cache) {
      setCachedABI({ blockchain: 'ethereum', contractAddress, abi: parsedABI });
    }
    return { result: parsedABI, error: null };
  }

  async apiFetch<T>(query: Record<string, string>): Promise<EtherscanParsedResponse<T>> {
    if (!this.apiURL) {
      throw new Error(`No API URL for ${this.baseURL}`);
    }
    const queryString = new URLSearchParams(query).toString();
    const url = `${this.apiURL}/api?${queryString}`;
    console.info(`GET ${url}`);
    const response = await fetch(url);
    const json: EtherscanApiResponse<T> = await response.json();
    if (json?.status !== '1') {
      console.error('Etherscan error:', json);
      return {
        error: json.message ?? 'unhandled etherscan error',
        result: json.result,
      };
    }
    return { result: json.result };
  }
}
