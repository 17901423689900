import { useColorModeValue } from '@chakra-ui/react';

export const useThemeColors = (): {
  containerBgColor: string;
  containerBorderColor: string;
  containerHoverBorderColor: string;
  buttonBgColor: string;
  buttonTextColor: string;
  textColor: string;
  textInputBgColor: string;
  textInputBorderColor: string;
  textInputPlaceholderColor: string;
} => {
  const containerBgColor = useColorModeValue('white', 'gray.800');
  const containerBorderColor = useColorModeValue('#dae1ea', '#364053');
  const containerHoverBorderColor = useColorModeValue('purple.300', 'purple.600');

  const textColor = useColorModeValue('#4e4e60', '#c8c8d6');

  const buttonBgColor = useColorModeValue('purple.500', 'purple.500');
  const buttonTextColor = useColorModeValue('white', 'white');

  const textInputBgColor = useColorModeValue('white', '#312f54');
  const textInputBorderColor = useColorModeValue('#b5a3f9', '#55519e');
  const textInputPlaceholderColor = useColorModeValue('#8490a0', '#7676ae');

  return {
    containerBgColor,
    containerBorderColor,
    containerHoverBorderColor,
    buttonBgColor,
    buttonTextColor,
    textColor,
    textInputBgColor,
    textInputBorderColor,
    textInputPlaceholderColor,
  };
};
