import { getDataURIParts, isProbablyDataURI } from './data-uri.utils';

export enum TokenURIType {
  AUDIO = 'AUDIO',
  IMAGE = 'IMAGE',
  JSON = 'JSON',
  UNKNOWN = 'UNKNOWN',
  VIDEO = 'VIDEO',
}

export enum TokenURIError {
  FETCH_ERROR = 'FETCH_ERROR',
  PARSE_ERROR = 'PARSE_ERROR',
}

interface MediaResponse {
  type: TokenURIType.AUDIO | TokenURIType.IMAGE | TokenURIType.VIDEO;
  embeddable: true;
}

export interface JSONResponse {
  type: TokenURIType.JSON;
  body: Record<string, unknown>;
  embeddable: false;
}

interface UnknownResponse {
  type: TokenURIType.UNKNOWN;
  embeddable: false;
}

type TokenURIResponse = JSONResponse | MediaResponse | UnknownResponse;

interface TokenURIMedia {
  error: null | TokenURIError;
  uri: string;
  uriNormalized: string;
  response: TokenURIResponse;
}

export interface TokenURIContent {
  error: null | TokenURIError;
  uri: string;
  uriNormalized: string;
  response?: TokenURIResponse;
  media?: TokenURIMedia;
}

export const getTokenURIContent = async (tokenURI: string): Promise<TokenURIContent> => {
  const uriNormalized = normalizeURI(tokenURI);

  const dataURI = tryParseDataURI(uriNormalized);
  if (dataURI.error) {
    return { error: dataURI.error, uri: tokenURI, uriNormalized };
  }
  if (dataURI.response) {
    // data of json/media is embedded in data URI, no need to fetch.
    const { type, embeddable } = dataURI.response;
    if ([TokenURIType.IMAGE, TokenURIType.AUDIO, TokenURIType.VIDEO].includes(type)) {
      // Media is at the tokenURI directly, not indirectly via JSON -> media URL
      const media = {
        error: null,
        uri: tokenURI,
        uriNormalized,
        response: {
          type,
          embeddable,
        },
      };
      return {
        error: null,
        uri: tokenURI,
        uriNormalized,
        response: { type, embeddable } as MediaResponse,
        media: media as TokenURIMedia,
      };
    } else if (type === TokenURIType.JSON) {
      const { body } = dataURI.response;
      try {
        const media = await tryExtractMedia({ json: body });
        return {
          error: null,
          uri: tokenURI,
          uriNormalized,
          response: {
            type,
            embeddable,
            body,
          },
          media,
        };
      } catch (e) {
        console.log('error parsing JSON data uri', e);
      }
    }
    return {
      error: TokenURIError.PARSE_ERROR,
      uri: tokenURI,
      uriNormalized,
    };
  }

  let response;
  try {
    response = await fetchWithFallback(uriNormalized);
  } catch (e) {
    console.log('error fetching tokenURI:', e);
    return {
      error: TokenURIError.FETCH_ERROR,
      uri: tokenURI,
      uriNormalized,
    };
  }

  const { error, response: parsedResponse } = await parseTokenURIResponse({ response });
  if (error || parsedResponse.type === TokenURIType.UNKNOWN) {
    return {
      error,
      uri: tokenURI,
      uriNormalized,
      response: parsedResponse,
    };
  }

  let media;
  if (parsedResponse.embeddable) {
    // Media is at the tokenURI directly, not indirectly via JSON -> media URL
    media = {
      error: null,
      uri: tokenURI,
      uriNormalized,
      response: {
        type: parsedResponse.type,
        embeddable: parsedResponse.embeddable,
      },
    };
  } else if (parsedResponse.type === TokenURIType.JSON) {
    media = await tryExtractMedia({ json: parsedResponse.body });
  }

  return {
    error: null,
    uri: tokenURI,
    uriNormalized,
    response: parsedResponse,
    media,
  };
};

const tryExtractMedia = async ({
  json,
}: Record<string, any>): Promise<TokenURIMedia | undefined> => {
  const uri = (json.image || json.image_url || json.imageUrl) as string | null;
  if (!uri) {
    return;
  }
  const uriNormalized = normalizeURI(uri);
  const dataURI = tryParseDataURI(uriNormalized);
  if (dataURI.error) {
    return;
  }
  if (dataURI.response) {
    // Media is embedded in data-uri, no need to fetch:
    const { type, embeddable } = dataURI.response;
    if ([TokenURIType.IMAGE, TokenURIType.AUDIO, TokenURIType.VIDEO].includes(type)) {
      return {
        error: null,
        uri,
        uriNormalized,
        response: { type, embeddable } as MediaResponse,
      };
    }
    // Can't parse data-uri, no need to proceed further:
    return;
  }

  try {
    const response = await fetchWithFallback(uriNormalized);
    const parsedResponse = await parseTokenURIResponse({ response });
    return {
      uri,
      uriNormalized,
      ...parsedResponse,
    };
  } catch (e) {
    console.log('error fetching tokenURI media:', e);
  }
};

const parseTokenURIResponse = async ({
  response,
}: {
  response: Response;
}): Promise<{ error: null | TokenURIError; response: TokenURIResponse }> => {
  const contentTypeHeader = response.headers.get('content-type');
  let error = null;
  const { type, embeddable } = parseContentType({ contentType: contentTypeHeader });

  if ([TokenURIType.IMAGE, TokenURIType.AUDIO, TokenURIType.VIDEO].includes(type)) {
    return {
      error: null,
      response: { type, embeddable } as MediaResponse,
    };
  }

  // JSON content type isn't always used for JSON. Sometimes octet-stream is used.
  try {
    const body = await response.json();
    return {
      error: null,
      response: { type: TokenURIType.JSON, embeddable: false, body },
    };
  } catch (e) {
    console.log('TokenURI utils: json parse error', e);
    // Only parse error if it was really likely to be JSON, not if content type is not recognized
    error = contentTypeHeader?.includes('application/json') ? TokenURIError.PARSE_ERROR : null;
  }

  return {
    error,
    response: { type: TokenURIType.UNKNOWN, embeddable: false },
  };
};

const parseContentType = ({
  contentType,
}: {
  contentType: string | null;
}): { type: TokenURIType; embeddable: boolean } => {
  if (contentType?.startsWith('image/')) {
    return {
      type: TokenURIType.IMAGE,
      embeddable: true,
    };
  }
  if (contentType?.startsWith('video/')) {
    return { type: TokenURIType.VIDEO, embeddable: true };
  }
  if (contentType?.startsWith('audio/')) {
    return { type: TokenURIType.AUDIO, embeddable: true };
  }
  if (contentType?.includes('application/json')) {
    return { type: TokenURIType.JSON, embeddable: false };
  }
  return { type: TokenURIType.UNKNOWN, embeddable: false };
};

export const preferredIpfsHost = 'ipfs.io';
const alternativeIpfsHost = 'cf-ipfs.com';

export const normalizeURI = (uri: string): string => {
  if (uri.startsWith('ipfs://')) {
    return uri.replace(/^ipfs:\/\//, `https://${preferredIpfsHost}/ipfs/`);
  }
  if (
    uri.startsWith(`http://${alternativeIpfsHost}/ipfs/`) ||
    uri.startsWith(`https://${alternativeIpfsHost}/ipfs/`)
  ) {
    return uri.replace(/^http:\/\//, 'https://').replace(alternativeIpfsHost, preferredIpfsHost);
  }
  if (uri.startsWith('ar://')) {
    return uri.replace(/^ar:\/\//, 'https://arweave.net/');
  }
  return uri;
};

// Data URI could be:
// image (or other media) base64 encoded
// JSON utf8 OR base64 encoded
// something else (unknown)
export const tryParseDataURI = (
  uri: string,
): { error: null | TokenURIError; response?: TokenURIResponse | null } => {
  if (!isProbablyDataURI(uri)) {
    return { error: null };
  }
  try {
    const parsedDataURL = getDataURIParts(uri);
    if (!parsedDataURL) {
      throw new Error('Unable to parse data URI');
    }

    // examples: "text/plain;charset=US-ASCII"
    // examples: "image/png"
    const { mimeType, isBase64, data } = parsedDataURL;
    const { type, embeddable } = parseContentType({ contentType: mimeType });

    if (type === TokenURIType.JSON) {
      let bodyDecoded = decodeURIComponent(data);
      if (isBase64) {
        bodyDecoded = atob(bodyDecoded);
      }
      const json = JSON.parse(bodyDecoded);
      return {
        error: null,
        response: {
          type,
          embeddable: false,
          body: json,
        },
      };
    }
    return {
      error: null,
      response: { type, embeddable } as MediaResponse | UnknownResponse,
    };
  } catch (e) {
    console.log('error parsing JSON data uri', e);
    return { error: TokenURIError.PARSE_ERROR };
  }
};

const fetchWithFallback = async (uri: string): Promise<Response> => {
  try {
    const response = await fetch(uri, { method: 'GET' });
    return response;
  } catch (e) {
    if (uri.startsWith('https://arweave.net')) {
      console.log('no fallback available for arweave..');
      throw e;
    }
    console.log('error fetching URI normally, trying cors buster and/or backup IPFS', e);
  }

  // Sometimes alt IPFS is better:
  if (uri.startsWith(`https://${preferredIpfsHost}/`)) {
    const backupIPFSUri = uri.replace(preferredIpfsHost, alternativeIpfsHost);
    const response = await fetch(backupIPFSUri, { method: 'GET' });
    return response;
  }

  // Try CORS-buster on non-IPFS, non-AR URIs:
  const response = await fetch(corsBusterURL(uri), { method: 'GET' });
  return response;
};

const corsBusterURL = (uri: string): string => {
  const workerHost = window.location.host.startsWith('localhost')
    ? 'corsify-staging.geeg.workers.dev'
    : 'corsify.geeg.workers.dev';
  return `https://${workerHost}/?url=${encodeURIComponent(uri)}`;
};
