import 'urlpattern-polyfill';
import { ChainId } from './chains';

export enum SupportedBlockchain {
  ETHEREUM = 'Ethereum',
  // POLYGON = 'Polygon',
  // AVALANCHE = 'Avalanche',
  // SOLANA = 'Solana',
  // FLOW = 'Flow',
  // TEZOS = 'Tezos',
  // UNKNOWN = 'Unknown',
}

export interface NFTURLInfo {
  blockchain: SupportedBlockchain;
  contractAddress: string;
  tokenId: string;
  marketplace: MarketplaceMetadata;
}

interface MarketplaceMetadata {
  name: string;
  hostname: string;
  patterns: Array<URLPattern>;
  inferredBlockchain?: SupportedBlockchain;
  fallbackBlockchain?: SupportedBlockchain;
}
const marketplaces: Array<MarketplaceMetadata> = [
  {
    name: 'OpenSea',
    hostname: 'opensea.io',
    // https://opensea.io/assets/ethereum/0x02beed1404c69e62b76af6dbdae41bd98bca2eab/4679
    // Note: Solana NFTs have no tokenId?
    // https://opensea.io/assets/solana/5cJmEtmvJWFhf5W9bPxjoDnVfWnmeDdJZdh2VLpsdwzN
    patterns: [
      new URLPattern({
        pathname: '/assets/:blockchain/:contractAddress/:tokenId',
      }),
      new URLPattern({
        // No tokenId for solana NFTs:
        pathname: '/assets/:blockchain/:contractAddress',
      }),
    ],
  },
  {
    name: 'LooksRare',
    hostname: 'looksrare.org',
    patterns: [
      // https://looksrare.org/collections/0x02BeeD1404c69e62b76Af6DbdaE41Bd98bcA2Eab/3537
      new URLPattern({
        pathname: '/collections/:contractAddress/:tokenId',
      }),
    ],
    inferredBlockchain: SupportedBlockchain.ETHEREUM,
  },
  {
    name: 'Gem',
    hostname: 'gem.xyz',
    patterns: [
      // https://www.gem.xyz/asset/0xbd3531da5cf5857e7cfaa92426877b022e612cf8/4307
      new URLPattern({
        pathname: '/asset/:contractAddress/:tokenId',
      }),
    ],
    inferredBlockchain: SupportedBlockchain.ETHEREUM,
  },
  {
    name: 'Etherscan',
    hostname: 'etherscan.io',
    patterns: [
      // https://etherscan.io/nft/0xbd3531da5cf5857e7cfaa92426877b022e612cf8/3274
      new URLPattern({
        pathname: '/nft/:contractAddress/:tokenId',
      }),
      // https://etherscan.io/token/0xbd3531da5cf5857e7cfaa92426877b022e612cf8?a=3274
      new URLPattern({
        pathname: '/token/:contractAddress',
        search: 'a=:tokenId',
      }),
    ],
    inferredBlockchain: SupportedBlockchain.ETHEREUM,
  },
  {
    name: 'Zapper',
    hostname: 'zapper.fi',
    patterns: [
      // https://zapper.fi/nft/0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d/7495?source=NFT%20collection%20page
      new URLPattern({
        pathname: '/nft/:contractAddress/:tokenId',
      }),
    ],
    inferredBlockchain: SupportedBlockchain.ETHEREUM,
  },
  {
    name: 'Genie',
    hostname: 'genie.xyz',
    patterns: [
      // https://www.genie.xyz/asset/0xc3f733ca98e0dad0386979eb96fb1722a1a05e69/10217?origin=collection
      new URLPattern({
        pathname: '/asset/:contractAddress/:tokenId',
      }),
    ],
    inferredBlockchain: SupportedBlockchain.ETHEREUM,
  },
  {
    name: 'X2Y2',
    hostname: 'x2y2.io',
    patterns: [
      // https://x2y2.io/eth/0x47ef181366da0fc170A2E62c51a995C79458ebEE/72
      new URLPattern({
        pathname: '/eth/:contractAddress/:tokenId',
      }),
    ],
    inferredBlockchain: SupportedBlockchain.ETHEREUM,
  },
  {
    name: 'sudoswap',
    hostname: 'sudoswap.xyz',
    // https://sudoswap.xyz/#/item/0x0427743DF720801825a5c82e0582B1E915E0F750/89
    patterns: [
      new URLPattern({
        hash: '/item/:contractAddress/:tokenId',
      }),
    ],
    inferredBlockchain: SupportedBlockchain.ETHEREUM,
  },
  {
    name: 'Rarible',
    hostname: 'rarible.com',
    patterns: [
      // https://rarible.com/token/0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d:3047?tab=overview
      new URLPattern({
        pathname: '/token/:contractAddress\\::tokenId',
      }),
      // https://rarible.com/token/immutablex/0xee972ad3b8ac062de2e4d5e6ea4a37e36c849a11:149437?tab=overview
      new URLPattern({
        pathname: '/token/:blockchain/:contractAddress\\::tokenId',
      }),
    ],
    fallbackBlockchain: SupportedBlockchain.ETHEREUM,
  },
  {
    name: 'NFTrade',
    hostname: 'nftrade.com',
    patterns: [
      // https://nftrade.com/assets/eth/0x2a187453064356c898cae034eaed119e1663acb8/24238210779799734428614718309756595549122085195533313614893012039347433513380
      new URLPattern({
        pathname: '/assets/:blockchain/:contractAddress/:tokenId',
      }),
    ],
  },
  {
    name: 'Element',
    hostname: 'element.market',
    patterns: [
      // https://www.element.market/assets/0x8270fc3b2d23de703b265b2abe008883954fea8e/1624
      new URLPattern({
        pathname: '/assets/:contractAddress/:tokenId',
      }),
      // https://www.element.market/assets/bsc/0x98eb46cbf76b19824105dfbcfa80ea8ed020c6f4/16582640342180
      new URLPattern({
        pathname: '/assets/:blockchain/:contractAddress/:tokenId',
      }),
    ],
    fallbackBlockchain: SupportedBlockchain.ETHEREUM,
  },
];
// // Solana-only:
// const MAGICEDEN_HOSTNAME = 'magiceden.io';
// const SOLANART_HOSTNAME = 'solanart.io';
// const SOLSEA_HOSTNAME = 'solsea.io';
// // Avax-only:
// const JOEPGS_HOSTNAME = 'joepegs.com';
// // Flow-only:
// const BLOCKTOBAY_HOSTNAME = 'bay.blocto.app';
// // Tezos-only:
// const OBJKT_HOSTNAME = 'objkt.com';

export enum NFTURLError {
  INVALID_URL = 'INVALID_URL',
  INVALID_MARKETPLACE_URL = 'INVALID_MARKETPLACE_URL',
  UNSUPPORTED_BLOCKCHAIN = 'UNSUPPORTED_BLOCKCHAIN',
  UNSUPPORTED_MARKETPLACE = 'UNSUPPORTED_MARKETPLACE',
}
export const extractNFTInfoFromURL = (
  rawURL: string,
): { error: NFTURLError | null; info?: NFTURLInfo } => {
  let url: URL;
  let normalizedURL = rawURL.trim();
  normalizedURL = !normalizedURL.startsWith('http') ? `https://${normalizedURL}` : normalizedURL;
  try {
    url = new URL(normalizedURL);
  } catch (e) {
    return { error: NFTURLError.INVALID_URL };
  }

  const host = url.host;
  if (!host) {
    return { error: NFTURLError.INVALID_URL };
  }

  let marketplace: MarketplaceMetadata | null = null;
  let result: URLPatternResult | null = null;
  for (const marketplaceOption of marketplaces) {
    if (!doesHostMatch({ urlHost: host, marketplace: marketplaceOption })) {
      continue;
    }

    for (const pattern of marketplaceOption.patterns) {
      const patternResult = pattern.exec(normalizedURL);
      if (patternResult) {
        marketplace = marketplaceOption;
        result = patternResult;
      }
    }

    // At this point, if the host matched but no matching URL was found then we can deduce that it's a supported marketplace but unsupported URL:
    if (!marketplace || !result) {
      return { error: NFTURLError.INVALID_MARKETPLACE_URL };
    }
  }

  // At this point, we've checked all hosts and none match, so it's not a marketplace we recognize:
  if (!marketplace || !result) {
    return { error: NFTURLError.UNSUPPORTED_MARKETPLACE };
  }
  let blockchain = marketplace.inferredBlockchain;
  // Search through path, hash, and search to see if any match groups:
  const contractAddress = getMatchFromURLPatternResult({ result, key: 'contractAddress' });
  const tokenId = getMatchFromURLPatternResult({ result, key: 'tokenId' });

  if (!blockchain) {
    const rawBlockchain = getMatchFromURLPatternResult({ result, key: 'blockchain' });
    if (rawBlockchain) {
      if (rawBlockchain.toLocaleLowerCase().startsWith('eth')) {
        blockchain = SupportedBlockchain.ETHEREUM;
      } else {
        return { error: NFTURLError.UNSUPPORTED_BLOCKCHAIN };
      }
    }
  }

  if (!blockchain && marketplace.fallbackBlockchain) {
    blockchain = marketplace.fallbackBlockchain;
  }

  if (!blockchain || !contractAddress || !tokenId) {
    return { error: NFTURLError.INVALID_MARKETPLACE_URL };
  }

  return {
    error: null,
    info: {
      blockchain,
      contractAddress,
      tokenId,
      marketplace,
    },
  };
};

const getMatchFromURLPatternResult = ({
  result,
  key,
}: {
  result: URLPatternResult;
  key: string;
}) => {
  return result.pathname.groups[key] || result.hash.groups[key] || result.search.groups[key];
};

const doesHostMatch = ({
  urlHost,
  marketplace,
}: {
  urlHost: string;
  marketplace: MarketplaceMetadata;
}): boolean => {
  const lowercaseHost = urlHost.toLocaleLowerCase();
  // Either matches exactly (opensea.io == opensea.io) or
  // the hostname ignoring subdomain matches (www.genie.xyz === genie.xyz)
  return (
    lowercaseHost === marketplace.hostname || lowercaseHost.endsWith(`.${marketplace.hostname}`)
  );
};

interface NFTExternalURL {
  title: string;
  logo: string;
  url: string;
}
interface CreateNFTExternalURLsProps {
  chainId: ChainId;
  address: string;
  tokenId: string;
}
export const createNFTExternalURLs = ({
  chainId,
  address,
  tokenId,
}: CreateNFTExternalURLsProps): Array<NFTExternalURL> => {
  if (chainId !== ChainId.ETH) {
    // only ETH for now
    return [];
  }

  return [
    {
      title: 'OpenSea',
      logo: '/external-logos/opensea.svg',
      url: `https://opensea.io/assets/ethereum/${address}/${tokenId}`,
    },
    {
      title: 'LooksRare',
      logo: '/external-logos/looksrare.svg',
      url: `https://looksrare.org/collections/${address}/${tokenId}`,
    },
    {
      title: 'X2Y2',
      logo: '/external-logos/x2y2.svg',
      url: `https://x2y2.io/eth/${address}/${tokenId}`,
    },
    {
      title: 'Gem.xyz',
      logo: '/external-logos/gem.png',
      url: `https://www.gem.xyz/asset/${address}/${tokenId}`,
    },
    // {
    //   title: 'Sudoswap',
    //   logo: '/external-logos/sudoswap.svg',
    //   url: `https://sudoswap.xyz/#/item/${address}/${tokenId}`,
    // },
    {
      title: 'Etherscan',
      logo: '/external-logos/etherscan.svg',
      url: `https://etherscan.io/nft/${address}/${tokenId}`,
    },
  ];
};
