import { BigNumber } from 'ethers';
import { ERC721Base, ERC721Enumerable, ERC721Metadata } from 'types/abis';
import { ContractExtensionType, contracts } from './contract.constants';
import { ExtendedProvider } from './providers';
import { getTokenURIContent, TokenURIContent } from './token-uri.utils';

interface ERC721InspectorProps {
  provider: ExtendedProvider;
  address: string;
  tokenId: string;
}
export class ERC721Inspector {
  provider: ExtendedProvider;
  address: string;
  tokenId: string;
  contractMetadata: typeof contracts.erc721;
  baseContract: ERC721Base;
  enumerableExtension?: ERC721Enumerable;
  metadataExtension?: ERC721Metadata;
  _memoizedTokenURI?: string | null;

  constructor({ provider, address, tokenId }: ERC721InspectorProps) {
    this.provider = provider;
    this.address = address;
    this.tokenId = tokenId;
    this.contractMetadata = contracts.erc721;
    this.baseContract = this.contractMetadata.factory.connect(address, provider);
  }

  async initExtensions(): Promise<void> {
    const enumerable = this.contractMetadata.extensions[ContractExtensionType.Enumerable];
    const hasEnumerableExtension = await this.baseContract.supportsInterface(enumerable.identifier);
    if (hasEnumerableExtension) {
      this.enumerableExtension = enumerable.factory.connect(this.address, this.provider);
    }

    const metadata = this.contractMetadata.extensions[ContractExtensionType.Metadata];
    const hasMetadataExtension = await this.baseContract.supportsInterface(metadata.identifier);
    if (hasMetadataExtension) {
      this.metadataExtension = metadata.factory.connect(this.address, this.provider);
    }
  }

  // Requires metadata ext
  async getName(): Promise<string | null> {
    if (this.metadataExtension) {
      try {
        const name = await this.metadataExtension.name();
        return name;
      } catch (e) {
        console.log('ERC721Inspector#getName error:', e);
      }
    }
    return Promise.resolve(null);
  }

  // Requires metadata ext
  async getSymbol(): Promise<string | null> {
    if (this.metadataExtension) {
      try {
        const symbol = await this.metadataExtension.symbol();
        return symbol;
      } catch (e) {
        console.log('ERC721Inspector#getSymbol error:', e);
      }
    }
    return Promise.resolve(null);
  }

  // Requires metadata ext
  async getTokenURI(): Promise<string | null> {
    if (this._memoizedTokenURI !== undefined) {
      return Promise.resolve(this._memoizedTokenURI);
    }
    if (this.metadataExtension) {
      try {
        const tokenURI = await this.metadataExtension.tokenURI(this.tokenId);
        this._memoizedTokenURI = tokenURI;
        return tokenURI;
      } catch (e) {
        console.log('ERC721Inspector#getTokenURI error:', e);
      }
    }
    this._memoizedTokenURI = null;
    return Promise.resolve(this._memoizedTokenURI);
  }

  async getTokenURIContent(): Promise<TokenURIContent | null> {
    const tokenURI = await this.getTokenURI();
    if (tokenURI) {
      return await getTokenURIContent(tokenURI);
    }
    return Promise.resolve(null);
  }

  // Requires enumerable ext
  async getTotalSupply(): Promise<BigNumber | null> {
    if (this.enumerableExtension) {
      try {
        const supply = await this.enumerableExtension?.totalSupply();
        return supply;
      } catch (e) {
        console.log('ERC721Inspector#getTotalSupply error:', e);
      }
    }
    return Promise.resolve(null);
  }
}
