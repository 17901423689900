import { PageContainer } from 'components/page-container';
import { Box, Center, Text } from '@chakra-ui/react';
import { useThemeColors } from 'hooks/use-theme-colors';

export const AboutScreen = () => {
  const { containerBgColor, containerBorderColor, textColor } = useThemeColors();

  return (
    <PageContainer>
      <Center>
        <Box
          backgroundColor={containerBgColor}
          borderColor={containerBorderColor}
          borderWidth="1px"
          borderRadius="md"
          padding="5"
          maxW="640px"
        >
          <Text fontSize="lg" color={textColor}>
            ArtScan is a tool that helps inspect whether or not an NFT's image and metadata are
            stored in a decentralized way. You can also see how closely it conforms to standards,
            and its blockchain history.
          </Text>
        </Box>
      </Center>
    </PageContainer>
  );
};
