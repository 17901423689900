import { BigNumber, ethers } from 'ethers';
import { ABIStateMutability } from 'types/abi-json.types';
import { ERC1155Base, ERC1155Metadata_URI } from 'types/abis';
import { findABIFunction } from './abi.utils';
import { ContractExtensionType, contracts } from './contract.constants';
import { EtherscanABIResult } from './etherscan';
import { ExtendedProvider } from './providers';
import { getTokenURIContent, TokenURIContent } from './token-uri.utils';

interface ERC1155InspectorProps {
  provider: ExtendedProvider;
  address: string;
  tokenId: string;
  abi?: EtherscanABIResult;
}
export class ERC1155Inspector {
  provider: ExtendedProvider;
  address: string;
  tokenId: string;
  contractMetadata: typeof contracts.erc1155;
  baseContract: ERC1155Base;
  abi?: EtherscanABIResult;
  metadataExtension?: ERC1155Metadata_URI;
  _memoizedTokenURI?: string | null;

  constructor({ provider, address, tokenId, abi }: ERC1155InspectorProps) {
    this.provider = provider;
    this.address = address;
    this.tokenId = tokenId;
    this.abi = abi;
    this.contractMetadata = contracts.erc1155;
    this.baseContract = this.contractMetadata.factory.connect(address, provider);
  }

  async initExtensions(): Promise<void> {
    const metadata = this.contractMetadata.extensions[ContractExtensionType.Metadata];
    const hasMetadataExtension = await this.baseContract.supportsInterface(metadata.identifier);
    const maybeHasMetadataExtension = findABIFunction({
      abi: this.abi as EtherscanABIResult,
      functionName: 'uri',
      stateMutability: ABIStateMutability.VIEW,
      inputTypes: ['uint256'],
      outputTypes: ['string'],
    });
    if (hasMetadataExtension || maybeHasMetadataExtension) {
      this.metadataExtension = metadata.factory.connect(this.address, this.provider);
    }
  }

  // not part of official spec but common
  async getName(): Promise<string | null> {
    if (!this.abi) {
      return null;
    }
    const nameFunction = findABIFunction({
      abi: this.abi,
      functionName: 'name',
      stateMutability: ABIStateMutability.VIEW,
      inputTypes: [],
      outputTypes: ['string'],
    });
    if (!nameFunction) {
      return null;
    }
    try {
      const contract = new ethers.Contract(this.address, [nameFunction], this.provider);
      return await contract.name();
    } catch (e) {
      console.log('error trying to fetch name:', e);
    }
    return null;
  }

  async getSymbol(): Promise<string | null> {
    if (!this.abi) {
      return null;
    }
    const symbolFunction = findABIFunction({
      abi: this.abi,
      functionName: 'symbol',
      stateMutability: ABIStateMutability.VIEW,
      inputTypes: [],
      outputTypes: ['string'],
    });
    if (!symbolFunction) {
      return null;
    }
    try {
      const contract = new ethers.Contract(this.address, [symbolFunction], this.provider);
      return await contract.symbol();
    } catch (e) {
      console.log('error trying to fetch symbol:', e);
    }
    return null;
  }

  // Requires metadata ext
  async getTokenURI(): Promise<string | null> {
    if (this._memoizedTokenURI !== undefined) {
      return Promise.resolve(this._memoizedTokenURI);
    }
    if (this.metadataExtension) {
      try {
        const tokenURI = await this.metadataExtension.uri(this.tokenId);
        this._memoizedTokenURI = tokenURI;
        return tokenURI;
      } catch (e) {
        console.log('ERC1155Inspector#getTokenURI error:', e);
      }
    }
    this._memoizedTokenURI = null;
    return Promise.resolve(this._memoizedTokenURI);
  }

  async getTokenURIContent(): Promise<TokenURIContent | null> {
    const tokenURI = await this.getTokenURI();
    if (tokenURI) {
      return await getTokenURIContent(tokenURI);
    }
    return Promise.resolve(null);
  }

  // Some ERC1155 have totalSupply, e.g.  Adidas Originals
  // https://docs.openzeppelin.com/contracts/4.x/api/token/erc1155#ERC1155Supply
  async getTotalSupply(): Promise<BigNumber | null> {
    if (!this.abi) {
      return null;
    }
    const totalSupplyFn = findABIFunction({
      abi: this.abi,
      functionName: 'totalSupply',
      stateMutability: ABIStateMutability.VIEW,
      // Note: unlike with ERC721, ERC1155 totalSupply usually wants a
      // tokenId in the inputs:
      inputTypes: ['uint256'],
      outputTypes: ['uint256'],
    });
    if (!totalSupplyFn) {
      return null;
    }
    try {
      const contract = new ethers.Contract(this.address, [totalSupplyFn], this.provider);
      return await contract.totalSupply(this.tokenId);
    } catch (e) {
      console.log('error trying to fetch totalSupply:', e);
    }
    return null;
  }
}
