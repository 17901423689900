import { Signer } from 'ethers';
import type { Provider } from '@ethersproject/providers';
import {
  ERC165__factory,
  ERC1155Base__factory,
  ERC1155Metadata_URI__factory,
  ERC721Base__factory,
  ERC721Enumerable__factory,
  ERC721Metadata__factory,
} from 'types/abis';

interface ContractFactory {
  createInterface: () => any;
  connect: (address: string, signerOrProvider: Signer | Provider) => any;
}

export enum ContractInterface {
  ERC165 = 'ERC-165',
  ERC721 = 'ERC-721',
  ERC1155 = 'ERC-1155',
}

export enum ContractExtensionType {
  Enumerable = 'Enumerable',
  Metadata = 'Metadata',
  Metadata_Unofficial = 'Metadata_Unofficial',
}

export interface ContractExtension {
  identifier: string;
  factory: ContractFactory;
}

// eslint-disable-next-line
interface ContractMetadataDefinition {
  type: ContractInterface;
  description: string;
  eip?: string;
  createdAt?: string;
  identifier: string;
  factory: ContractFactory;
  extensions?: { [key in ContractExtensionType]?: ContractExtension };
}

const erc165 = {
  type: ContractInterface.ERC165,
  description: 'Standard Interface Detection',
  eip: 'https://eips.ethereum.org/EIPS/eip-165',
  createdAt: '2018-01-23',
  identifier: '0x01ffc9a7',
  factory: ERC165__factory,
};

const erc721 = {
  type: ContractInterface.ERC721,
  description: 'Non-Fungible Token Standard',
  eip: 'https://eips.ethereum.org/EIPS/eip-721',
  createdAt: '2018-01-24',
  identifier: '0x80ac58cd',
  factory: ERC721Base__factory,
  extensions: {
    [ContractExtensionType.Enumerable]: {
      identifier: '0x780e9d63',
      factory: ERC721Enumerable__factory,
    },
    [ContractExtensionType.Metadata]: {
      identifier: '0x5b5e139f',
      factory: ERC721Metadata__factory,
    },
  },
};

const erc1155 = {
  type: ContractInterface.ERC1155,
  description: 'Multi Token Standard',
  eip: 'https://eips.ethereum.org/EIPS/eip-1155',
  createdAt: '2018-06-17',
  identifier: '0xd9b67a26',
  factory: ERC1155Base__factory,
  extensions: {
    [ContractExtensionType.Metadata]: {
      identifier: '0x0e89341c',
      factory: ERC1155Metadata_URI__factory,
    },
  },
};

// export const contracts: { [contractType: string]: ContractMetadataDefinition } = {
export const contracts = {
  // Introspection support:
  erc165,
  // Standard NFT contracts
  erc721,
  erc1155,
};

export type ContractMetadata = typeof contracts[keyof typeof contracts];
