import { extendTheme } from '@chakra-ui/react';
import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools';

export const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fonts: {
    heading: `'Work Sans', -apple-system, Helvetica, Arial, sans-serif`,
    body: `'DM Sans',-apple-system, Helvetica, Arial, sans-serif`,
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        color: mode('gray.800', 'whiteAlpha.900')(props),
        bg: mode('gray.50', '#212838')(props),
      },
    }),
  },
});
