import { ABIEntryType, ABIFunctionEntry, ABIStateMutability } from 'types/abi-json.types';
import { EtherscanABIResult } from './etherscan';

interface FindABIFunctionProps {
  abi: EtherscanABIResult;
  functionName: string;
  stateMutability?: ABIStateMutability;
  inputTypes?: Array<string>;
  outputTypes?: Array<string>;
}
export const findABIFunction = ({
  abi,
  functionName,
  stateMutability,
  inputTypes,
  outputTypes,
}: FindABIFunctionProps): ABIFunctionEntry | null => {
  const result = abi.find((entry) => {
    const functionMatch = entry.type === ABIEntryType.FUNCTION && entry.name === functionName;
    if (!functionMatch) {
      return false;
    }

    let inputTypesMatch = true;
    if (inputTypes) {
      const lengthMatch = inputTypes.length === entry.inputs.length;
      const typesMatch =
        lengthMatch && inputTypes.every((type, i) => entry.inputs[i].type === type);
      inputTypesMatch = lengthMatch && typesMatch;
    }
    let outputTypesMatch = true;
    if (outputTypes) {
      const lengthMatch = outputTypes.length === entry.outputs.length;
      const typesMatch =
        lengthMatch && outputTypes.every((type, i) => entry.outputs[i].type === type);
      outputTypesMatch = lengthMatch && typesMatch;
    }

    const stateMutabilityMatch = stateMutability ? entry.stateMutability === stateMutability : true;

    return stateMutabilityMatch && inputTypesMatch && outputTypesMatch;
  });
  return result ? (result as ABIFunctionEntry) : null;
};
