import React from 'react';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { theme } from './theme';
import { DashboardScreen } from 'screens/dashboard';
import { InspectorScreen } from 'screens/inspector';
import { ChainId } from 'utils/chains';
import { AboutScreen } from 'screens/about';

export const App = () => {
  return (
    <Providers>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Routes>
        <Route path="/" element={<DashboardScreen />} />
        <Route path="/about" element={<AboutScreen />} />
        <Route path="/eth/:address/:tokenId" element={<InspectorScreen chainId={ChainId.ETH} />} />
      </Routes>
    </Providers>
  );
};

const Providers = ({ children }: React.PropsWithChildren) => {
  return (
    <BrowserRouter>
      <ChakraProvider resetCSS theme={theme}>
        {children}
      </ChakraProvider>
    </BrowserRouter>
  );
};
