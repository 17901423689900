import { getInfuraProjectId } from './env';

export enum ChainId {
  ETH = 1,
  MATIC = 137,
  ARBITRUM = 42161,
}

interface Chain {
  name: string;
  gasToken: string;
  rpcURL: string;
  explorerURL: string;
}

type Chains = { [chainId in ChainId]: Chain };

export const chains: Chains = {
  [ChainId.ETH]: {
    name: 'Ethereum',
    gasToken: 'ETH',
    rpcURL: `https://mainnet.infura.io/v3/${getInfuraProjectId()}`,
    explorerURL: 'https://etherscan.io',
  },
  [ChainId.MATIC]: {
    name: 'Polygon',
    gasToken: 'MATIC',
    rpcURL: 'https://polygon-rpc.com',
    explorerURL: 'https://polygonscan.com',
  },
  [ChainId.ARBITRUM]: {
    name: 'Arbitrum',
    gasToken: 'ETH',
    rpcURL: 'https://arb1.arbitrum.io/rpc',
    explorerURL: 'https://arbiscan.io',
  },
};
