// Broadly categorize contract:
// - Is it a contract account or EOA? https://ethereum.org/en/developers/docs/accounts/#types-of-account
// -
// TODO:
// check function exists using estimateGas:
// - https://ethereum.stackexchange.com/a/127112
// - https://ethereum.stackexchange.com/a/73815
// Optimize with multicall?
// - https://github.com/cavanmflynn/ethers-multicall (seems better)
// - https://github.com/Destiner/ethcall
import { ErrorCode } from '@ethersproject/logger';
import detectProxyTarget from 'evm-proxy-detection';
import { ContractMetadata, contracts } from './contract.constants';
import { Etherscan, EtherscanABIError, FetchABIAPI } from './etherscan';
import { ExtendedProvider } from './providers';

interface EthersError extends Error {
  code?: ErrorCode;
}

// https://docs.ethers.io/v5/single-page/#/v5/api/providers/provider/-%23-Provider-getCode
const GET_CODE_NO_CONTRACT_RESPONSE = '0x';

interface NonNFTContractDetails {
  canIntrospect: boolean;
  contractMetadata: null;
  abi: FetchABIAPI;
}
export interface NFTContractDetails {
  canIntrospect: true;
  contractMetadata: ContractMetadata | null;
  abi: FetchABIAPI;
}

type ContractDetails = NFTContractDetails | NonNFTContractDetails;

export class ContractCategorizer {
  provider: ExtendedProvider;
  address: string;
  debug: boolean;
  etherscan: Etherscan;
  _memoizedABI?: FetchABIAPI;
  // _memoizedERC165?:

  constructor({
    provider,
    address,
    debug = false,
  }: {
    provider: ExtendedProvider;
    address: string;
    debug?: boolean;
  }) {
    this.provider = provider;
    this.address = address;
    this.debug = debug;
    this.etherscan = new Etherscan({
      name: 'Etherscan',
      apiURL: 'https://api.etherscan.io',
      baseURL: 'https://etherscan.io',
    });
  }

  async isContract(): Promise<boolean> {
    const bytecode = await this.provider.getCode(this.address);
    this.log('ContractCategorizer#isContract', { bytecode });
    return bytecode !== GET_CODE_NO_CONTRACT_RESPONSE;
  }

  // TODO: See also https://github.com/OpenZeppelin/openzeppelin-contracts-upgradeable/blob/master/contracts/token/ERC721/IERC721Upgradeable.sol
  // See also if can just use supportsInterface: https://github.com/OpenZeppelin/openzeppelin-contracts-upgradeable/blob/e30d4dc7f488eb4beb2174ef4e68d7a5eacfb897/contracts/access/AccessControlUpgradeable.sol#L84
  // Doesn't work on some proxies: 0x3b3ee1931dc30c1957379fac9aba94d1c48a5405
  async proxyTarget(): Promise<string | null> {
    const requestFunc = ({ method, params }: { method: string; params: any[] }) => {
      return this.provider.send(method, params);
    };

    const target = await detectProxyTarget(
      this.address, // e.g. 0xA7AeFeaD2F25972D80516628417ac46b3F2604Af
      requestFunc,
    );
    return target;
  }

  // Sample verified: https://etherscan.io/address/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405#code
  // Sample unverified: https://etherscan.io/address/0x000000000085359dbc0eb45911e5f3f7a532a07e#code
  async getContractABI(): Promise<FetchABIAPI> {
    if (this._memoizedABI != null) {
      return this._memoizedABI;
    }
    this._memoizedABI = await this.etherscan.fetchABI({ contractAddress: this.address });
    return this._memoizedABI;
  }

  async isContractVerified(): Promise<boolean | null> {
    const abi = await this.getContractABI();
    if (abi.error === EtherscanABIError.RATE_LIMIT_REACHED) {
      return null;
    }
    return !!abi.result;
  }

  async getContractDetails(): Promise<ContractDetails> {
    // ERC165 (https://eips.ethereum.org/EIPS/eip-165) implements a
    // `supportsInterface` function that lets us introspect the contract
    // further. Not all contracts implement this interface though.
    const { erc165 } = contracts;
    const erc165Contract = erc165.factory.connect(this.address, this.provider);
    let canIntrospect = false;
    try {
      canIntrospect = await erc165Contract.supportsInterface(erc165.identifier);
    } catch (e) {
      const err = e as EthersError;
      if (err?.code !== ErrorCode.CALL_EXCEPTION) {
        this.log('ContractCategorizer#canIntrospect unexpected error:', err);
      }
      canIntrospect = false;
    }

    const abi = await this.getContractABI();
    if (!canIntrospect) {
      return {
        canIntrospect,
        contractMetadata: null,
        abi,
      };
    }

    const contractMetadata = await this.getNFTContractMetadata();
    return {
      canIntrospect: true,
      contractMetadata,
      abi,
    };
  }

  private async getNFTContractMetadata(): Promise<ContractMetadata | null> {
    const { erc165, erc721, erc1155 } = contracts;
    const erc165Contract = erc165.factory.connect(this.address, this.provider);

    const isERC721 = await erc165Contract.supportsInterface(erc721.identifier);
    if (isERC721) {
      return erc721;
    }

    const isERC1155 = await erc165Contract.supportsInterface(erc1155.identifier);
    if (isERC1155) {
      return erc1155;
    }

    return null;
  }

  private async log(...args: any) {
    if (this.debug) {
      console.log(...args);
    }
  }
}
