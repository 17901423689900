// https://pub.dev/documentation/web3dart/latest/contracts/StateMutability.html
export enum ABIStateMutability {
  VIEW = 'view',
  PURE = 'pure',
  NON_PAYABLE = 'nonpayable',
  PAYABLE = 'payable',
}

export enum ABIEntryType {
  CONSTRUCTOR = 'constructor',
  EVENT = 'event',
  FUNCTION = 'function',
}

// {internalType: 'string', name: '_name', type: 'string'}
export interface StandardInputOrOutput {
  internalType: string;
  name: string;
  type: string;
}

export interface ABIConstructorEntry {
  type: ABIEntryType.CONSTRUCTOR;
  inputs: Array<StandardInputOrOutput>;
  stateMutability: ABIStateMutability;
}

export interface ABIEventEntry {
  type: ABIEntryType.EVENT;
  name: string; // "OwnershipTransferred"
  anonymous: boolean;
  // {indexed: true, internalType: 'address', name: 'previousOwner', type: 'address'}
  inputs: Array<{ indexed: boolean; internalType: string; name: string; type: string }>;
}

export interface ABIFunctionEntry {
  type: ABIEntryType.FUNCTION;
  name: string; // "setPrice"
  anonymous: boolean;
  inputs: Array<StandardInputOrOutput>;
  outputs: Array<StandardInputOrOutput>;
  stateMutability: ABIStateMutability;
}

export type ABIEntry = ABIConstructorEntry | ABIEventEntry | ABIFunctionEntry;
