import { HStack, Image, Link, Tooltip } from '@chakra-ui/react';
import { useMemo } from 'react';
import { ChainId } from 'utils/chains';
import { createNFTExternalURLs } from 'utils/nft-marketplace.utils';

interface NFTMarketplaceLinksProps {
  chainId: ChainId;
  address: string;
  tokenId: string;
}

export const NFTMarketplaceLinks = ({ chainId, address, tokenId }: NFTMarketplaceLinksProps) => {
  const urls = useMemo(() => {
    return createNFTExternalURLs({ chainId, address, tokenId });
  }, [chainId, address, tokenId]);

  if (!urls?.length) {
    return <></>;
  }

  return (
    <HStack spacing="4">
      {urls.map((externalUrl) => {
        return (
          <Link href={externalUrl.url} isExternal key={externalUrl.title}>
            <Tooltip label={`View on ${externalUrl.title}`}>
              <Image
                src={externalUrl.logo}
                maxW="26px"
                maxH="26px"
                // opacity={'0.5'}
                filter="grayscale(100%) opacity(70%);"
                _hover={{ filter: 'none' }}
              />
            </Tooltip>
          </Link>
        );
      })}
    </HStack>
  );
};
