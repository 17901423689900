import { useCallback, useEffect, useMemo, useState } from 'react';
import { PageContainer } from 'components/page-container';
import { useProvider } from 'hooks/use-provider';
import { ChainId } from 'utils/chains';
import { ContractCategorizer, NFTContractDetails } from 'utils/contract-categorizer';
import { ContractInterface } from 'utils/contract.constants';
import { ERC721Inspector } from 'utils/erc721-inspector';
import { useParams } from 'react-router-dom';
import { ERC1155Inspector } from 'utils/erc1155-inspector';
import { TokenURIContent, TokenURIType } from 'utils/token-uri.utils';
import { Box, Center, HStack, Spinner } from '@chakra-ui/react';
import { useThemeColors } from 'hooks/use-theme-colors';
import { NFTMarketplaceLinks } from 'components/nft-marketplace-links';
import { NFTPreview } from 'components/nft-preview';

type InspectorScreenRouteParams = {
  address: string;
  tokenId: string;
};
interface InspectorScreenProps {
  chainId: ChainId;
}

export const InspectorScreen = ({ chainId }: InspectorScreenProps) => {
  const { address, tokenId } = useParams() as InspectorScreenRouteParams;
  const provider = useProvider({ chainId });
  const [contractDetails, setContractDetails] = useState<NFTContractDetails>();
  const [tokenURIContent, setTokenURIContent] = useState<TokenURIContent | null>(null);

  const contractCategorizer = useMemo(() => {
    return new ContractCategorizer({ provider, debug: true, address });
  }, [provider, address]);

  useEffect(() => {
    const categorizeContract = async () => {
      const isContract = await contractCategorizer.isContract();
      if (!isContract) {
        console.log('no contract found at this address!');
        return;
      }

      const contractDetails = await contractCategorizer.getContractDetails();
      console.log('contract details:', contractDetails);

      if (contractDetails.canIntrospect && contractDetails.contractMetadata) {
        setContractDetails(contractDetails);
      }
    };
    categorizeContract();
  }, [address]);

  useEffect(() => {
    console.log('found NFT contract!');

    const processNFT = async (nft: ERC721Inspector | ERC1155Inspector) => {
      await nft.initExtensions();

      console.log('nft:', nft);

      const name = await nft.getName();
      const symbol = await nft.getSymbol();
      const totalSupply = await nft.getTotalSupply();
      console.log({ name, symbol, totalSupply });

      const tokenURI = await nft.getTokenURI();
      console.log({ tokenId, tokenURI });

      const content = await nft.getTokenURIContent();
      console.log('tokenURIContent:', content);
      setTokenURIContent(content);
    };

    if (contractDetails?.contractMetadata?.type === ContractInterface.ERC721) {
      processNFT(new ERC721Inspector({ provider, address, tokenId }));
    } else if (contractDetails?.contractMetadata?.type === ContractInterface.ERC1155) {
      processNFT(
        new ERC1155Inspector({ provider, address, tokenId, abi: contractDetails.abi.result }),
      );
    }
  }, [contractDetails, address, tokenId]);

  return (
    <PageContainer>
      <HStack align="flex-start" spacing="15px">
        <Box>
          <NFTPreviewContainer tokenURIContent={tokenURIContent} />
          <Center marginTop="4">
            <NFTMarketplaceLinks chainId={chainId} address={address} tokenId={tokenId} />
          </Center>
        </Box>
        <Box background={'white'} flex="1">
          TODO
        </Box>
      </HStack>
    </PageContainer>
  );
};

const NFTPreviewContainer = ({ tokenURIContent }: { tokenURIContent: TokenURIContent | null }) => {
  const { containerBgColor, containerBorderColor, containerHoverBorderColor } = useThemeColors();

  const mediaURI = tokenURIContent?.media?.uriNormalized;
  const canOpenMediaURI = tokenURIContent?.media?.response?.type === TokenURIType.IMAGE;
  const openNFTURL = useCallback(() => {
    if (mediaURI && canOpenMediaURI) {
      if (mediaURI.startsWith('data:')) {
        const image = new Image();
        image.src = mediaURI;
        const newTab = window.open();
        if (newTab) {
          newTab.document.body.innerHTML = image.outerHTML;
        }
      } else {
        window.open(mediaURI, '_blank');
      }
    }
  }, [mediaURI, canOpenMediaURI]);

  return (
    <Box
      backgroundColor={containerBgColor}
      borderColor={containerBorderColor}
      borderWidth="1px"
      borderRadius="md"
      width="350px"
      alignItems="center"
      justifyItems="center"
      padding="15px"
      onClick={openNFTURL}
      _hover={
        canOpenMediaURI
          ? {
              borderColor: containerHoverBorderColor,
              cursor: 'pointer',
            }
          : {}
      }
    >
      <Center height="100%" minH="320px">
        {tokenURIContent ? (
          <NFTPreview tokenURIContent={tokenURIContent} />
        ) : (
          <Spinner color="purple.500" size="lg" />
        )}
      </Center>
    </Box>
  );
};
