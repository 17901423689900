export const isProbablyDataURI = (uri?: string | null): boolean => {
  return uri?.startsWith('data:') ?? false;
};

export const getDataURIParts = (
  uri: string,
): { mimeType: string; isBase64: boolean; data: string } | null => {
  try {
    const url = new URL(uri);
    if (url.protocol !== 'data:' || !url.pathname?.length) {
      return null;
    }

    const firstCommaIndex = url.pathname.indexOf(',');
    if (firstCommaIndex <= 0) {
      return null;
    }

    // "application/json;base64"
    // "application/json;utf-8"
    // "image/svg+xml;base64"
    const mimeTypeAndEncoding = url.pathname.slice(0, firstCommaIndex).toLocaleLowerCase();
    const data = url.pathname.slice(firstCommaIndex + 1);
    if (!mimeTypeAndEncoding.length || !data.length) {
      return null;
    }

    const mimeType = mimeTypeAndEncoding.split(';')[0];
    const isBase64 = mimeTypeAndEncoding.endsWith('base64');

    return { mimeType, isBase64, data };
  } catch (e) {
    console.log('error parsing data uri:', e);
  }
  return null;
};
